import React, { Component } from "react";
import PureComponent from "../pure";

import ProductComponent from "./Quotes/ProductComponent";
import OppProductComponent from "./Opportunities/OppProductComponent";

import InviteesContainer from "../popup/calendarWindow/inviteesContainer";
import DetailTextEditor from "./DetailTextEditor/DetailTextEditor"
import ACLRoles from "./ACLRoles/ACLRoles";

import  sAction  from "sAction";

class DetailCustomComponent extends PureComponent {
  render() {
    const module = this.props.module;
    const way = this.props.way;
    const prefix = this.props.prefix;
    const view = this.props.view;
    const data = this.props.data;
    const readonly = this.props.readonly;
        let renderComp = null;

        if(view == "calendar"){
            return null;
        }
       switch (module){
          case "Meetings":
          case "Calls":
              renderComp = <div className="detailInvComp detailInvCustComp">
                  <div className="header"><div>{sAction.translate("LBL_PARTICIPANTS_TAB", "Calendar")}</div></div>
                  <InviteesContainer data={data.toJS().invitees} prefix={way+"/invitees"} detailWay={prefix} pomClass="detailView" />
                  </div>;
              break;
            case "Quotes":
                const cenotvorba = sAction.dataGet(prefix+"/fields/cenotvorba/value");
                renderComp = <ProductComponent data={data.get("productData")} cenotvorba={cenotvorba} way={way+"/productData"} readonly={readonly}/>
                break;
            case "Opportunities":
                renderComp = <OppProductComponent data={data.get("productData")} way={way+"/productData"} readonly={readonly}/>
                break;
          case "ACLRoles":
                if (data.get("recordId")) {
                  renderComp = <ACLRoles way={way} data={data}/>
                }
                break;
            default:
                if(data.get("textEditor")){
                    renderComp = <DetailTextEditor data={data.get("textEditor")} way={way+"/textEditor"} prefix={prefix} module={module}/>
                }
        }

    return renderComp;
  }
}
export default DetailCustomComponent;
