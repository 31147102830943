import React from "react";
import PureComponent from "../pure";

import ReportWindow from "./reportWindow/ReportWindow";
import RelateListView from "./relateListView/RelateListView";
import CalWindowContainer from "./calendarWindow/calWindowContainer";
import CalSettingsForm from "../calendar/calSettingsForm";
import AuditLog from "./auditLog/auditLog";
import ExportList from "./exportList/ExportList";
import DetailViewPopup from "./DetailView/DetailViewPopup";
import FileUpload from "./Files/FileUpload";
import FileDownload from "./Files/FileDownload";
import FilePreview from "./Files/FilePreview";
import Button from "../formElements/Button";

import sAction from "sAction";
import EmailAddContact from "../email/EmailAddContact";
import EmailSettingsOePopup from "./email/EmailSettingsOePopup";
import EmailSelectFolderPopup from "./email/EmailSelectFolderPopup";
import EmailSignaturePopup from "./email/EmailSignaturePopup";
import AresChooseAccountPopup from "./detailApi/AresChooseAccountPopup";
import FinstatChooseAccountPopup from "./detailApi/FinstatChooseAccountPopup";
import ChangesDetectedPopup from "./listview/ChangesDetectedPopup";
import ChooseAddressPopup from "./detailApi/ChooseAddressPopup";
import ContractSelectTemplate from "./detailApi/ContractSelectTemplate";
import OppChooseTemplate from "./detailApi/OppChooseTemplate";
import CloseOpportunityPopup from "./detailApi/CloseOpportunityPopup";
import AccHierarchyPopup from "./detailApi/AccHierarchyPopup";
import CloseQuotePopup from "./detailApi/CloseQuotePopup";

import OppDuplicatePopup from "./detailApi/OppDuplicatePopup";
import OppDuplicateQuotePopup from "./detailApi/OppDuplicateQuotePopup";
import OppCreateQuoteFromTemplate from "./detailApi/OppCreateQuoteFromTemplate";

import QuoteCreateTemplatePopup from "./detailApi/QuoteCreateTemplatePopup";

import UserSetReplacementPopup from "./detailApi/UserSetReplacementPopup";
import LoginAsRegUserPopup from "./detailApi/LoginAsRegUserPopup";

import EmailCompose from "../email/EmailCompose";
import SweetSpot from "./../SweetSpot/SweetSpot";
import Loader from "../loader";
import RolesPopup from "./roles/Roles";
import AddGiftPopup from "./DetailView/addGiftPopup";
import ActivitiesTimeline from "../RightPanel/ActivitiesTimeline/ActivitiesTimeline";
import DynamicEnumPopup from "./DynamicEnumPopup/DynamicEnumPopup";
import ChangePasswordPopup from "./Users/ChangePasswordPopup";

import AddRelate from "./tags/AddRelate";
import TagRelatesList from "./tags/TagRelatesList";

import PopupAcmDate from "./popupAcmDate/PopupAcmDate";
import PopupAcmDateTime from "./popupAcmDate/popupAcmDateTime";

import CreateMultipleCalls from "./createMultipleCalls/CreateMultipleCalls";
import Test_invoicetemplate from "./acm_templateinvoicing/Test_invoicetemplate";
import Image from "./image/Image";
import SeekyAllActivities from "./seeky/seekyAllActivities";

import EditFieldsPopup from "./DetailView/EditFieldsPopup"
import CustomModuleTranslate from "./DetailView/CustomModuleTranslate"

import MapAdditionalData from './import/MapAdditionalData';
import CreateMultipleActivities from "./createMultipleActivities/CreateMultipleActivities"

import DefaultListViewEdit from "./listview/DefaultListViewEdit"

class Popup extends PureComponent {
  constructor(props) {
    super(props);
    this.popup = React.createRef();
    this.popupContainer = React.createRef();

    this.moveData = {
      move: false,
      x: null,
      y: null,
      startX: null,
      startY: null,
    };
  }
  componentDidUpdate() {
    super.componentDidUpdate();
  }
  mouseDown(e) {
    var data = this.props.data;
    var popupData = data.data;
    if (popupData.get("popupMove") === false) {
      return false;
    }

    if (this.moveData.move == false) {
      this.moveData.x = e.clientX;
      this.moveData.y = e.clientY;
      this.moveData.move = true;
      this.popupContainer.current.classList.add("movePopup");
      var startX = 0;
      var startY = 0;
      if (this.popupContainer.current.style.left != "") {
        startX = parseInt(
          this.popupContainer.current.style.left.replace("px", "")
        );
      }
      if (this.popupContainer.current.style.top != "") {
        startY = parseInt(
          this.popupContainer.current.style.top.replace("px", "")
        );
      }
      this.moveData.startX = startX;
      this.moveData.startY = startY;
    }
  }
  mouseMove(e) {
    if (this.moveData.move) {
      const x = e.clientX;
      const y = e.clientY;

      const diffX = x - this.moveData.x;
      const diffY = y - this.moveData.y;
      this.popupContainer.current.style.left =
        this.moveData.startX + diffX + "px";
      this.popupContainer.current.style.top =
        this.moveData.startY + diffY + "px";
    }
  }
  mouseUp(e) {
    this.moveData.move = false;
    this.popupContainer.current.classList.remove("movePopup");
  }
  close(e) {
    var data = this.props.data;
    var popupData = data.data;
    const classes = e.target.classList;
    if (classes != undefined) {
      if ((classes.contains("popupShow") && popupData.get("closeFromBc") !== false) || classes.contains("popupCloseIcon")) {
        this.forceClose();
        //
      }
    }
  }
  forceClose() {
    this.popup.current.classList.add("popupHide");
    const data = this.props.data;
    if (data.content === 'popupDetail') {
      sAction.removeStorage(`changes-${data.data.get('detailView').module}-${sAction.dataGet('conf/user/id')}`);
    }
    setTimeout(() => {
      const closeFnc = this.props.data.data.get("onClose");
      sAction.popupHide(closeFnc);
    }, 200);
  }
  callback(callBackData) {
    const data = this.props.data;
    const popupData = data.data;
    const callback = popupData.get("callback");

    if (callback !== undefined) {
      callback(callBackData);
    }
  }
  render() {
    var data = this.props.data;
    var content = data.content;
    var popupData = data.data;
    var customStyle = null;
    var customClass = null;
    const way = this.props.way;
    var popupContainer = true;
    // hodnota true => zabrani zavreni popupu klikem mimo okno
    // popup bude mozne zavrit pouze krizkem vpravo nahore
    var preventClose = false;

    switch (content) {
      case "reportWindow":
        content = (
          <ReportWindow
            data={this.props.reportWindow}
            listData={this.props.popupListData}
            close={() => this.forceClose()}
          />
        );
        customStyle = { width: "90%", height: "80%" };
        break;

      case "ContractSelectTemplate":
        content = <ContractSelectTemplate data={popupData} templates={data.templates}/>;
        break;

      case "relateListView":
        content = (
          <RelateListView
            popupData={popupData}
            data={popupData.get("listView")}
          />
        );
        customClass = data.content;
        customStyle = { width: "80%", height: "70%", padding: "0px" };
        break;

      case "roles":
        content = <RolesPopup popupData={popupData} />;
        break;

      case "load":
        content = <Loader />;
        break;
      case "popupDetail":
        content = <DetailViewPopup data={popupData} way={this.props.way} />;
        customClass = "detailPopup";
        customStyle={zIndex : "98"}
        break;
      case "changesCheck":
        content = <ChangesDetectedPopup data={popupData} />;
        // customClass = "detailPopup";
        break;
      case "calQuickEdit":
        //editView
        const module = popupData.get("module");
        const prefix = popupData.get("prefix");
        const pomProms = sAction.dataGet(prefix);
        var dataCal = {
          calQuickCreate: pomProms.detailView,
          meetingView: pomProms.meetingView,
          callView: pomProms.callView,
          taskView: pomProms.taskView,
          invitees: pomProms.invitees,
          activeTab: pomProms.activeTab,
          menuOpen: pomProms.menuOpen,
        };

        content = (
          <CalWindowContainer
            data={dataCal}
            type="editView"
            module={module}
            prefix={prefix}
          />
        );

        customStyle = { width: "80%", minWidth: "990px" };

        break;

      case "calSettingsForm":
        content = <CalSettingsForm data={popupData.get("setting")} />;
        customClass = "calSettingsForm-popup";
        break;

      case "calQuickCreate":
        //createView
        const prefixC = popupData.get("prefix");
        const pomPromsC = sAction.dataGet(prefixC);
        var dataCal = {
          calQuickCreate: pomPromsC.detailView,
          meetingView: pomPromsC.meetingView,
          callView: pomPromsC.callView,
          taskView: pomPromsC.taskView,
          invitees: pomPromsC.invitees,
          activeTab: pomPromsC.activeTab,
          menuOpen: pomPromsC.menuOpen,
        };
        customStyle = { width: "80%", minWidth: "990px" };

        content = (
          <CalWindowContainer
            data={dataCal}
            type="createView"
            prefix={prefixC}
            way={way}
          />
        );
        break;

      case "auditLog":
        content = (
          <AuditLog
            module={popupData.get("module")}
            parentId={popupData.get("parentId")}
          />
        );
        customClass = "auditLog";
        break;

      case "emailAddContact":
        customClass = "email__compose-popup"
        content = <EmailAddContact data={popupData} />;
        break;

      case "emailCompose":
        let popupId = sAction.getPopupId();
        content = <EmailCompose data={popupData} way={"conf/popup" + popupId + "/data"} />;
        customClass = "email__compose-popup";
        preventClose = true;
        break;

      case "emailSettingsOePopup":
        content = <EmailSettingsOePopup data={popupData} />;
        break;

      case "emailSelectFolderPopup":
        content = <EmailSelectFolderPopup data={popupData} />;
        break;

      case "emailSignaturePopup":
        content = <EmailSignaturePopup data={popupData} />;
        break;

      case "aresChooseAccountPopup":
        content = <AresChooseAccountPopup data={popupData} />;
        break;
      case "finstatChooseAccountPopup":
        content = <FinstatChooseAccountPopup data={popupData} />;
        break;

      case "ChooseAddressPopup":
        content = <ChooseAddressPopup data={popupData} />;
        break;
      case "printOpportunity":
        content = <OppChooseTemplate data={popupData} />;
        break;
      case "closeOpportunity":
        content = <CloseOpportunityPopup data={popupData} />;
        break;
      case "accHierarchy":
        content = <AccHierarchyPopup data={popupData} />;
        break;
      case "oppDuplicateQuote":
        content = <OppDuplicateQuotePopup data={popupData.get("params")} />;
        break;
      case "oppDuplicate":
        content = <OppDuplicatePopup data={popupData.get("params")} />;
        customClass = "OppDuplicatePopup";
        break;
      case "oppCreateQuoteFromTemplate":
        content = <OppCreateQuoteFromTemplate data={popupData.get("params")} />;
        break;
      case "quoteCreateTemplate":
        content = <QuoteCreateTemplatePopup data={popupData.get("params")} />;
        break;
      case "closeQuote":
        content = <CloseQuotePopup data={popupData} />;
        break;
      case "userSetReplacement":
        content = <UserSetReplacementPopup data={popupData.get("params")} />;
        break;
      case "loginAsRegUser":
        content = <LoginAsRegUserPopup data={popupData.get("params")} />;
        break;
      case "SweetSpot":
        content = <SweetSpot data={popupData} />;
        customClass = "sweetSpot-popup";
        break;
      case "fileUpload":
        content = <FileUpload data={popupData} parent={this} />;
        break;
      case "fileDownload":
        content = <FileDownload data={popupData} parent={this} />;
        break;
      case "filePreview":
        content = <FilePreview data={popupData} parent={this} />;
        break;
      case "DynamicEnumPopup":
        content = <DynamicEnumPopup data={popupData} way={way} />;
        break;
      case "defaultListViewEdit":
          content = <DefaultListViewEdit data={popupData} way={way} />;
          customStyle = { width: "90%", height: "80%" };
      break;
      case "exportList":
        content = (
          <div className="exportListPopupContent">
            <ExportList
              columns={popupData.get("columns")}
              onCreate={(data) => this.callback(data)}
              close={() => this.forceClose()}
            />
          </div>
        );
        break;
      case "addGift":
        content = <AddGiftPopup data={popupData} />;
        customClass = "relateListView";
        customStyle = { width: "80%", padding: "0px" };
        break;
      case "activitiesTimeline":
        content = (
          <ActivitiesTimeline prefix={"conf/popup/data"} data={popupData} />
        );
        break;
      case "changePassword":
        content = (
          <ChangePasswordPopup
            data={popupData}
            close={() => this.forceClose()}
          />
        );
        break;
      case "tagAddRelate":
        content = <AddRelate data={popupData} way={way} />;
        break;
      case "TagRelatesList":
        content = <TagRelatesList data={popupData} way={way} />;
        break;
      case "acmDate":
        content = <PopupAcmDate data={popupData} />;
        popupContainer = false;
        break;
      case "acmDateTime":
        content = <PopupAcmDateTime data={popupData} />;
        popupContainer = false;
        break;
      case "image":
        content = <Image data={popupData} />;
        customClass = "image-popup";
        // popupContainer = false
        break;
      case "test_invoicetemplate":
        content = (
          <Test_invoicetemplate
            data={popupData}
            close={() => this.forceClose()}
          />
        );
        break;
      case "seekyAllActivities":
        content = (
          <SeekyAllActivities data={popupData} />
        );
        break;
      case "createMultipleCalls":
        content = <CreateMultipleCalls data={popupData} way={way} />;
        break;
      case "editFieldsNewField":
        content = <EditFieldsPopup data={popupData} way={way} />;
      break;
      case 'MapAdditionalData':
        customStyle = {width: '30%'};
        content = <MapAdditionalData data={popupData}/>;
      break;
      case 'createMultipleActivities':
        customClass = "createMultipleActivities"
        content = <CreateMultipleActivities data={popupData} way={way} />;
      break;
      case 'customModuleTranslate':
          content = <CustomModuleTranslate data={popupData} way={way} />;
      break;
      default:
        if (typeof content === "string") {
          content = content.replace(/\\n/g, "<br>");
          content = (
            <div className="acmPopupContent">
              <div
                className="acmPopupContentWrapper"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          );
        }
      // jinak se vyrenderuje vlozena komponenta tak jak je
    }
    var header = null;
    const headerData = popupData.get("header");
    var classNameColor = popupData.get("color");
    if (headerData != undefined) {
      const headerIcon = popupData.get("icon");
      var className = "acmPopupHeader";
      if (classNameColor != undefined) {
        className += " " + classNameColor;
      }

      let iconData = null;
      if(headerIcon){
        iconData = <div className={"popupHeaderIcon "+headerIcon} />
      }


      header = (
        <div className={className} onMouseDown={(e) => this.mouseDown(e)}>
          {iconData}
          {headerData}
        </div>
      );
    }

    var buttons = [];
    const buttonData = popupData.get("buttons");
    if (buttonData !== undefined) {
      buttonData.forEach((button, index) => {
        buttons.push(
          <Button key={index} onClick={() => button.get("callback")()} className={button.get("className")}>
            {button.get("label")}
          </Button>
        );
      });
    }

    const paramCustomStyle = popupData.get("customStyle");
    if (customStyle == null && paramCustomStyle != undefined) {
      customStyle = paramCustomStyle.toJS();
    }
    const paramCustomClass = popupData.get("customClass");

    if (customClass == null && paramCustomClass != undefined) {
      customClass = paramCustomClass;
    }

    return (
      <React.Fragment>
        {popupContainer == true ? (
          <div
            onMouseMove={(e) => this.mouseMove(e)}
            onMouseUp={(e) => this.mouseUp(e)}
            ref={this.popup}
            onClick={(e) => this.close(e)}
            className={
              data.show && !preventClose ? "acmPopup popupShow" : "acmPopup"
            }
            style={{zIndex : this.props.data.content == "popupDetail" ? "105" : "106"}}
          >
            <div
              ref={this.popupContainer}
              className={"popupContent " + customClass}
              style={customStyle}
            >
              <div className="icon-Close popupCloseIcon" />
              {header}
              {content}
              {buttons.length != 0 && (
                <div className="acmPopupButtons">{buttons}</div>
              )}
            </div>
          </div>
        ) : (
          content
        )}
      </React.Fragment>
    );
  }
}
export default Popup;
